import {DateType, EnumType, JacksonType, JsonIdentityInfo, JsonIgnore} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Arquivo} from "./arquivo";
import {Contador} from "./contador";
import {DadosCadastro} from "./dados.cadastro";
import {DadosEndereco} from "./dados.endereco";
import {DadosPessoa} from "./dados.pessoa";
import {Endereco} from "./endereco";
import {Fiscal} from "./fiscal";
import {Municipio} from "./municipio";
import {Papel} from "./papel";
import {Preferencias} from "./preferencias";
import {RegimeISS} from "./regime.i.s.s";
import {Telefone} from "./telefone";
import {VinculoAtividade} from "./vinculo.atividade";
import {OrigemEnum} from "../enums/origem.enum";
import {NaturezaCadastroEnum} from "../enums/natureza.cadastro.enum";
import {SituacaoPessoaEnum} from "../enums/situacao.pessoa.enum";
import {WebISSStatusEnum} from "../enums/web.iss.status.enum";
import {WebISSTipoContribuinteEnum} from "../enums/web.iss.tipo.contribuinte.enum";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {DocumentoPipe} from "../modules/core/pipes/documento.pipe";
import {PerfilEnum} from "../enums/perfil.enum";

@JacksonType("Pessoa")
@JsonIdentityInfo()
export class Pessoa extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  areaOcupada: number;

  areaTotal: number;

  @DateType()
  cadastroInicial: Date;

  @JacksonType("Contador")
  contadorResponsavel: Contador;

  @DateType()
  dataAbertura: Date;

  documento: string;

  email: string;

  emiteGuiaAvulsaPorNota: boolean = false;

  emiteGuiaPorNota: boolean = false;

  @JacksonType("Fiscal")
  fiscalResponsavel: Fiscal;

  geraApenasReciboQuandoRetencao: boolean = false;

  grpMobiliario: string;

  @JacksonType("Arquivo")
  imagen: Arquivo;

  inscMun: string;

  inscMunWebIss: string;

  isentoTsaPrestador: boolean;

  isentoTsaTomador: boolean;

  @EnumType(NaturezaCadastroEnum)
  naturezaCadastro: NaturezaCadastroEnum;

  nome: string = "";

  orgaoPublico: boolean;

  orgaoPublicoOutros: boolean;

  @EnumType(OrigemEnum)
  origem: OrigemEnum = OrigemEnum.cadastro;

  @JacksonType("Preferencias")
  preferencias: Preferencias;

  prestador: boolean = false;

  ramo: string;

  @JacksonType("RegimeISS")
  regimeISS: RegimeISS;

  @JacksonType("Municipio")
  responsavel: Municipio;

  selecionada: boolean = false;

  @EnumType(SituacaoPessoaEnum)
  situacao: SituacaoPessoaEnum;

  substitutoTributario: boolean = false;

  @DateType()
  ultimaAlteracao: Date;

  @DateType()
  ultimaSincronia: Date;

  @EnumType(WebISSStatusEnum)
  webIssStatus: WebISSStatusEnum;

  @EnumType(WebISSTipoContribuinteEnum)
  webIssTipoContribuinte: WebISSTipoContribuinteEnum;

  @JacksonType("VinculoAtividade")
  atividadesAtuais: VinculoAtividade[];

  @JacksonType("VinculoAtividade")
  atividadesHistorico: VinculoAtividade[];

  @JacksonType("Endereco")
  enderecos: Endereco[];

  @JacksonType("Telefone")
  telefones: Telefone[];

  @JacksonType("Papel")
  papeis: Papel[];

  // local

  @JacksonType("DadosEndereco")
  dadosEndereco: DadosEndereco;

  @JacksonType("DadosPessoa")
  dadosPessoa: DadosPessoa;

  @JacksonType("DadosCadastro")
  dadosCadastro: DadosCadastro;

  docNomeTrunc: string;

  @JsonIgnore()
  imagem: number;

  // methods

  getDisplayValue(): string {
    return this.getDocumentoFmt() + ' - ' + this.nome;
  }

  isEventual(): boolean{
    if(!this.naturezaCadastro || this.naturezaCadastro == NaturezaCadastroEnum.EVENTUAL){
      return true;
    }
    return false;
  }

  getTelefonePrincipal(): string{
    let numeroTel: string;
    if (null == this.telefones || 0 == this.telefones.length) {
      return null;
    }

    this.telefones.forEach(telefone => {
      if(telefone.principal == true){
        numeroTel =  telefone.numero;
        return;
      }
    });
    return numeroTel;
  }

  getDocumentoFmt(): string {
    let documentoPipe = new DocumentoPipe();

    return documentoPipe.transform(this.documento);
  }

  possuiPerfil(perfil: PerfilEnum): boolean{
    let perfilEncontrado: boolean = false;
    this.papeis.forEach(papel => {
      if (perfil == papel.perfil) {
        perfilEncontrado = true;
        return;
      }
    });
    return perfilEncontrado;
  }
}
