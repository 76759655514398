import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Logradouro} from "./logradouro";
import {Pessoa} from "./pessoa";
import {TipoEnderecoEnum} from "../enums/tipo.endereco.enum";

@JacksonType("Endereco")
@JsonIdentityInfo()
export class Endereco extends SonnerBaseEntity {

  id: number;

  bairro: string = "";

  cep: string = "";

  complemento: string = "";

  @JacksonType("Logradouro")
  logradouro: Logradouro;

  numero: string = "";

  pais: string = "";

  @JacksonType("Pessoa")
  pessoa: Pessoa;

  principal: boolean = false;

  @EnumType(TipoEnderecoEnum)
  tipoEndereco: TipoEnderecoEnum;
}
