export class StringUtils {


  public static somenteNumero(value: string): string {
    if(value){
      return value.replace(/[^0-9]/g, '')
    }
    return '';
  };

  public static trunc(value: string, tamanho: number): string {
    if(value){
      if(value.length <= tamanho){
        return value;
      }else{
        return value.substring(0, tamanho) + "...";
      }
    }
    return '';
  };

}
