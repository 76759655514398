import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Municipio} from "./municipio";
import {TipoLogradouroEnum} from "../enums/tipo.logradouro.enum";

@JacksonType("Logradouro")
@JsonIdentityInfo()
export class Logradouro extends SonnerBaseEntity {

  id: number;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  @EnumType(TipoLogradouroEnum)
  tipo: TipoLogradouroEnum = TipoLogradouroEnum.rua;
}
