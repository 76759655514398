import {EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {TipoCNAEEnum} from "../enums/tipo.cnae.enum";

@JacksonType("AtividadeCNAE")
@JsonIdentityInfo()
export class AtividadeCNAE extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  classe: string;

  descricao: string;

  divisao: string;

  grupo: string;

  secao: string;

  subclasse: string;

  @EnumType(TipoCNAEEnum)
  tipo: TipoCNAEEnum;

  versao: string;

  getDisplayValue(): string {
    return this.subclasse + " - " + this.descricao;
  }
}
