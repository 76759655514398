import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Atividade} from "./atividade";
import {SolicitacaoAlteracaoNFe} from "./solicitacao.alteracao.n.fe";
import {UnidadeItem} from "./unidade.item";

@JacksonType("ItemSolicitacaoAlteracaoNFe")
@JsonIdentityInfo()
export class ItemSolicitacaoAlteracaoNFe extends SonnerBaseEntity {

  id: number;

  aliquota: number;

  @JacksonType("Atividade")
  atividade: Atividade;

  descricao: string;

  base: number;

  quantidade: number;

  @JacksonType("SolicitacaoAlteracaoNFe")
  solicitacao: SolicitacaoAlteracaoNFe;

  valorUnitario: number;

  @JacksonType("UnidadeItem")
  unidade: UnidadeItem;
}
