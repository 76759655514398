import {JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {GrupoAtividade} from "./grupo.atividade";
import {Municipio} from "./municipio";
import {ValueDisplayable} from "../modules/ui/interface/value.displayable";
import {StringUtils} from "../modules/core/utils/string.utils";

@JacksonType("Atividade")
@JsonIdentityInfo()
export class Atividade extends SonnerBaseEntity implements ValueDisplayable {

  id: number;

  abateObra: boolean = false;

  aceitaEdicaoAliquota: boolean = false;

  aliquota: number;

  ativa: boolean  = false;

  codigo: string = "";

  cnae: string;

  @JacksonType("GrupoAtividade")
  grupo: GrupoAtividade;

  isenta: boolean = false;

  @JacksonType("Municipio")
  municipio: Municipio;

  nome: string;

  passivelFatorR: boolean = false;

  percentualMaximoOutrasDeducoes: number;

  permiteIncidenciaForaMunicipio: boolean = false;

  permiteOutrasDeducoes: boolean = false;

  permiteRetencaoNoMunicipio: boolean = false;

  resumo: string;

  retida: boolean = false;

  UPFMD: boolean = false;

  pedagio: boolean = false;

  transporteColetivo: boolean = false;

  aliquotaNotaAvulsa: number;

  anexoSimples: number;

  tomadorNaoIdentificado: boolean = false;

  retemLocalPrestacao: boolean = false;

  codigoFormatado() {
    let codigoFormatado: string = "";

    if (this.grupo != null) {
      codigoFormatado += this.grupo.codigo;
      codigoFormatado += this.municipio.codAtvSep;
    }

    codigoFormatado += this.codigo;

    return codigoFormatado;
  }

  getDisplayValue(): string {
    return this.codigoFormatado() + " - " + this.nome;
  }

  aliquotaFormatada(): string {
    return '( ' +(this.aliquota * 100)+' % )';
  }

  getRotulo(): string{
    if (this.resumo) {
      return this.resumo;
    } else {
      return this.nome;
    }
  }

  getDescricaoTruncada(): string{
    return StringUtils.trunc(this.getRotulo(),65)
  }
}
