import {DateType, EnumType, JacksonType, JsonIdentityInfo, JsonSubType} from "@sonner/jackson-service-v2";
import {Documento} from "./documento";
import {Pessoa} from "./pessoa";
import {RelatorioAssincrono} from "./relatorio.assincrono";
import {Solicitacao} from "./solicitacao";
import {PapelServicoEnum} from "../enums/papel.servico.enum";
import {SituacaoSolicitacaoEnum} from "../enums/situacao.solicitacao.enum";
import {TipoLivroEnum} from "../enums/tipo.livro.enum";
import {TipoSolicitacaoEnum} from "../enums/tipo.solicitacao.enum";

@JacksonType("SolicitacaoAutenticacaoLivroFiscal")
@JsonSubType({key: 'br.com.sonner.iss.entity.SolicitacaoAutenticacaoLivroFiscal', type: 'Solicitacao'})
@JsonIdentityInfo()
export class SolicitacaoAutenticacaoLivroFiscal extends Solicitacao {

  ano: number;

  mes: number;

  @EnumType(PapelServicoEnum)
  papelServico: PapelServicoEnum;

  @JacksonType("RelatorioAssincrono")
  relatorio: RelatorioAssincrono;

  @EnumType(TipoLivroEnum)
  tipoLivro: TipoLivroEnum;

  id: number;

  @JacksonType("Pessoa")
  aprovador: Pessoa;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @DateType()
  dataDeferimento: Date;

  @DateType()
  dataIndeferimento: Date;

  @DateType()
  dataSolicitacao: Date;

  @JacksonType("Documento")
  documento: Documento;

  grpProcessado: boolean;

  @JacksonType("Pessoa")
  indeferidor: Pessoa;

  motivoDeferimento: string;

  motivoIndeferimento: string;

  obs: string;

  @EnumType(SituacaoSolicitacaoEnum)
  situacao: SituacaoSolicitacaoEnum;

  @JacksonType("Pessoa")
  solicitante: Pessoa;

  @EnumType(TipoSolicitacaoEnum)
  tipo: TipoSolicitacaoEnum;

  @DateType()
  ultimaExp: Date;
}
