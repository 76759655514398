import {DateType, EnumType, JacksonType, JsonIdentityInfo} from "@sonner/jackson-service-v2";
import {SonnerBaseEntity} from "../modules/core/classes/sonner.base.entity";
import {Mensagem} from "./mensagem";
import {Municipio} from "./municipio";
import {Pessoa} from "./pessoa";
import {AvaliacaoAdministradorEnum} from "../enums/avaliacao.administrador.enum";
import {SituacaoTopicoEnum} from "../enums/situacao.topico.enum";

@JacksonType("Topico")
@JsonIdentityInfo()
export class Topico extends SonnerBaseEntity {

  id: number;

  avaliacao: string;

  @EnumType(AvaliacaoAdministradorEnum)
  avaliacaoAdministrador: AvaliacaoAdministradorEnum = AvaliacaoAdministradorEnum.naoAvaliado;

  @JacksonType("Pessoa")
  contribuinte: Pessoa;

  @JacksonType("Pessoa")
  criador: Pessoa;

  @DateType()
  dataFechamento: Date;

  @JacksonType("Pessoa")
  fiscal: Pessoa;

  @JacksonType("Municipio")
  municipio: Municipio;

  notaAvaliacao: number;

  @EnumType(SituacaoTopicoEnum)
  situacao: SituacaoTopicoEnum = SituacaoTopicoEnum.aberto;

  @DateType()
  ultimaAlteracao: Date;

  @JacksonType("Mensagem")
  mensagens: Mensagem[];

  criptId: string;

  conteudoPrimeiraMensagem() {
    return this.mensagens[0].conteudo;
  }

}
